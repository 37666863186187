import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Grid,
  Box
} from "@mui/material";
import { EstudianteExamen } from "../../interfaces/IMonitoreo";
import { getIndividualReport } from "../../services/reportServices";

interface ReportStudentDialogProps {
  openModal: boolean;
  handleCloseModal: () => void;
  students: EstudianteExamen[];
}

const ReportStudentDialog: React.FC<ReportStudentDialogProps> = ({
  openModal,
  handleCloseModal,
  students,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedStudent, setSelectedStudent] = useState<EstudianteExamen | null>(null);

  // Filter students based on the search term
  const filteredStudents = students.filter((student: EstudianteExamen) =>
    student.estudiante.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleStudentDownload = async () => {
    if (!selectedStudent) return;

    try {
      const response = await getIndividualReport(selectedStudent.examenUsuarioId);
      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `student_${selectedStudent.examenUsuarioId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading student report:", error);
    }
  };
  return (
<Dialog
  open={openModal}
  onClose={handleCloseModal}
  PaperProps={{
    sx: {
      height: "80vh", 
      width: "50vw",  
      maxHeight: "100vh", 
      maxWidth: "100vw",  
    },
  }}
>      <DialogTitle>Selecciona al estudiante</DialogTitle>
<DialogContent>
  {/* Sticky Search Input */}
  <Box
    sx={{
      position: "sticky", 
      top: 0,
      backgroundColor: "white", 
      zIndex: 1, 
      paddingBottom: 2,
    }}
  >
    <TextField
      fullWidth
      label="Buscar estudiante"
      variant="outlined"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      margin="normal"
    />
  </Box>

  {filteredStudents.map((student: EstudianteExamen) => (
    <Grid key={student.examenUsuarioId} container alignItems="center">
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedStudent === student}
            onChange={() => setSelectedStudent(student)}
          />
        }
        label={student.estudiante.nombre}
      />
    </Grid>
  ))}

  {filteredStudents.length === 0 && (
    <p>No se encontraron estudiantes con ese nombre.</p>
  )}
</DialogContent>

      <DialogActions>
        <Button onClick={handleCloseModal} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleStudentDownload}
          color="primary"
          disabled={!selectedStudent}
        >
          Descargar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportStudentDialog;
