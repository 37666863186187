import axios from "axios";
import { actionButton, ExamenMonitoreo, ExamenResponse } from "../interfaces/IMonitoreo";

export const getExamStudents = async (id: string): Promise<ExamenMonitoreo> => {
  const response = await axios.get(`https://backexams-624915669123.us-central1.run.app/api/monitor/examen/${id}`);
  return response.data;
};

  export const updateStatusById = async (id: string, action : actionButton): Promise<ExamenResponse> => {
    var url = "";
    if(action === 'continuar'){
      url = 'https://backexams-624915669123.us-central1.run.app/api/monitor/examen/reanudar/';
    }else if(action === 'cerrar'){
      url = 'https://backexams-624915669123.us-central1.run.app/api/monitor/examen/detener/';
    }else if(action === 'pausa'){
        url = 'https://backexams-624915669123.us-central1.run.app/api/monitor/examen/pausar/';
    }
    const response = await axios.patch(url+id);
    return response.data;
  };
