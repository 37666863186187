import axios from "axios";
import { User } from "../interfaces/IUser";

export const getSustentantesService = async (): Promise<User[]> => {
    const response = await axios.get('https://backexams-624915669123.us-central1.run.app/admin/user/');
    return response.data;
  };

  export const updateSustentante = async (idExamen: string, updatedData: any) => {
    console.log(updatedData)
    const url = 'https://backexams-624915669123.us-central1.run.app/admin/user/editar/'+idExamen;
    const response = await axios.patch(url, updatedData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  export const getUserAdminService = async (): Promise<User[]> => {
    const response = await axios.get('https://backexams-624915669123.us-central1.run.app/admin/user/admin');
    return response.data;
  };

  export const updateUserAdmin = async (idExamen: string, updatedData: any) => {
    console.log(updatedData)
    const url = 'https://backexams-624915669123.us-central1.run.app/admin/'+idExamen;
    const response = await axios.patch(url, updatedData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };