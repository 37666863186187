import axios from 'axios';
import { LoginResponse, IRegister, LoginAdminResponse } from '../interfaces/IUser';

export const loginService = async (email: string, password: string)  => {
    try {
        const response = await axios.post<LoginResponse>('https://backexams-624915669123.us-central1.run.app/auth/login', {
            email,
            password,
        });

       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};
export const loginAdminService = async (email: string, password: string)  => {
    try {
        const response = await axios.post<LoginAdminResponse>('https://backexams-624915669123.us-central1.run.app/admin/auth/login', {
            email,
            password,
        });

       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};
export const registerService = async (body: IRegister)  => {
    try {
    //    const response = await axios.post<LoginResponse>('https://backexams-624915669123.us-central1.run.app/api/users/crear', body);
       const response = await axios.post<LoginResponse>('https://backexams-624915669123.us-central1.run.app/api/users/crear/inscribir', body); 

       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};

export const logoutService = async (uid: string)  => {
    try {
       const response = await axios.post<LoginResponse>('https://backexams-624915669123.us-central1.run.app/auth/logout', {"usuarioId": uid});
       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};

export const logoutAdminService = async (uid: string)  => {
    try {
       const response = await axios.post<LoginResponse>('https://backexams-624915669123.us-central1.run.app/auth/logout', {"usuarioId": uid});
       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};
