import React from "react";
import { Box, Typography, Button, TextField, Card, CardMedia, CardContent, InputAdornment, OutlinedInput, InputLabel, FormControl } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Examen1 from "../../assets/img/exam-1.png";

interface ExamInfoBoxProps {
  onSearchChange: (value: string) => void;
  examName: string;
}

const ExamInfoBox: React.FC<ExamInfoBoxProps> = ({ onSearchChange, examName }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        margin: "0 auto",
      }}
    >
      <CardMedia
        component="img"
        image={Examen1}
        alt="Exam"
        sx={{ width: 80, height: 80, borderRadius: "50%", marginRight: 2 }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" fontWeight="bold" color="#1e293b">
          {examName}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
        <FormControl sx={{ m: 1 , width:'35%'}}>
          <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            placeholder="Buscar por Nombre o Correo"
            startAdornment={<InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>}
            label="Amount"
            size="small"
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </FormControl>
          {/* <TextField
            variant="outlined"
            placeholder="Buscar por participante"
            size="small"
            onChange={(e) => onSearchChange(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            sx={{ flexGrow: 1 }}
          /> */}
 
        </Box>
      </CardContent>
    </Box>
  );
};

export default ExamInfoBox;
