import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  Typography,
  Box,
  Chip,
  Stack,
  IconButton,
} from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import BookIcon from "@mui/icons-material/LibraryBooks";
import Examen1 from "../../assets/img/exam-1.png";
import { Examen } from "../../interfaces/IExam";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
interface ExamenCardProps {
  examen: Examen;
  callback: (examenId: number) => void;
  isDetail?: boolean;
  origen?: string;
}
const ExamCard = ({ examen, callback, isDetail, origen }: ExamenCardProps) => {
  const [open, setOpen] = useState(false);
  const [expired, setExpired] = useState(false);

  const validateExpiredExam = (applicationDate: string): boolean => {
    const currentDate = new Date();
    const examDate = new Date(applicationDate); 
    console.log("VALIDATE", applicationDate, "vs", currentDate.toISOString());
    return currentDate < examDate; 
  };

  useEffect( () => {
    const isExpired = validateExpiredExam(examen.fechaAplicacion);
    setExpired(isExpired);
  },[])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderRadius: 2,
          boxShadow: 1,
          marginBottom: 2
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: 100,
            height: 100,
            borderRadius: 1,
          }}
          image={Examen1}
          alt="Course image"
        />

        {/* Content Section */}
        <CardContent sx={{ flex: "1", paddingLeft: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            {examen.nombre}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: 0.5 }}
          >
            {examen.descripcion}
          </Typography>

          {/* Tags */}
          {!isDetail && (
            <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mt={1}
              >
                <IconButton size="small" color="secondary" sx={{ padding: 0 }}>
                  <QuizIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2" color="textSecondary" ml={1}>
                  {examen.totalPreguntas} preguntas
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mt={1}
              >
                <IconButton size="small" color="secondary" sx={{ padding: 0 }}>
                  <BookIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2" color="textSecondary" ml={1}>
                  {examen.totalModulos} Módulos
                </Typography>
              </Box>
            </Stack>
          )}
        </CardContent>

        <Box sx={{ textAlign: "right" }}>
          {!isDetail && (
            <>
              <Typography variant="body2" color="textSecondary">
                Duración
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {examen.tiempoLimite} minutos
              </Typography>
            </>
          )}
          {isDetail ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {!expired  || origen == 'admin'? 
              <IconButton color="primary" onClick={() => callback(examen.id)}>
              <ArrowForwardIosIcon />
            </IconButton>:
              <Typography sx={{color: "red"}}>{"No disponible"}</Typography> 
              
              }

            </Box>
          ) : (
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={handleClickOpen}
              color="secondary"
            >
              {"Inscribir"}
            </Button>
          )}
        </Box>
      </Card>
      {/* Alert Modal */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        ¿Quieres proceder con la inscripción para el examen?
      </DialogTitle>
      <DialogContent sx={{ padding: 3 }}>
        <Typography variant="body1" color="textSecondary">
          Al confirmar tu inscripción, quedará registrado tu acceso al examen.
          Podrás realizarlo cuando estés listo.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={() => callback(examen.id)}
          variant="outlined"
          color="primary"
          sx={{ ml: 1 }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default ExamCard;
