import * as React from "react";
import {
  CardContent,
  Typography,
  CircularProgress,
  Paper,
  Chip,
  Grid2 as Grid,
  TablePagination,
  TextField,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
import useFetchData from "../../hooks/services";
import { getUserAdminService } from "../../services/userServices";
import { Admin } from "../../interfaces/IUser";
import SearchIcon from '@mui/icons-material/Search';
import { Table, TableBody, TableCell, TableHead, TablePaper, TableRow } from "../Table/CustomeTable";
import { useNavigate } from "react-router-dom";

const ControlUsuarios: React.FC = () => {
  const { data: users, loading } = useFetchData(() => getUserAdminService(), []);
  const [filteredUsers, setFilteredUsers] = React.useState<any[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate(); 

  React.useEffect(() => {
    if (users) {
      const filtered = users.filter((user: Admin) => {
        const fullName = `${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`.toLowerCase();
        const email = user.email.toLowerCase();
        const searchLower = searchText.toLowerCase();
  
        return fullName.includes(searchLower) || email.includes(searchLower) ;
      });
      setFilteredUsers(filtered);
    }
  }, [users, searchText]);

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const handleEditClick = (user: Admin) => {
    navigate(`/control/usuarios/${user.id}`, { state: { user } });
  };
  if (loading) {
    return <CircularProgress color="primary" />;
  }

  if (!users || users.length === 0) {
    return <Typography>No se encontraron usuarios registrados</Typography>;
  }

  return (
    <CardContent>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>


        <Grid sx={{ width: '100%', display:'flex'}}> 
          <Box sx={{ width: '60%'}}>
          <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
            Usuarios
          </Typography>
 
          </Box>
           <FormControl sx={{ m: 1 , width:'35%'}}>
          <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            placeholder="Buscar por Nombre"
            startAdornment={<InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>}
            label="Amount"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
          />
        </FormControl>
        </Grid>
      </Grid>

      <TablePaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user: Admin) => (
                <TableRow key={user.id}>
                  <TableCell>{user.nombre+" "+user.apellidoPaterno+" "+user.apellidoMaterno}</TableCell>
                
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditClick(user)}
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TablePaper>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 15]}
          labelRowsPerPage="Filas por página"
        />
      </Box>
    </CardContent>
  );
};

export default ControlUsuarios;
