import { useEffect } from 'react';

const TextSelectionBlocker: React.FC = () => {
  useEffect(() => {
    const preventSelection = (event: Event) => event.preventDefault();
    document.addEventListener('selectstart', preventSelection);

    // Limpia el evento al desmontar el componente
    return () => {
      document.removeEventListener('selectstart', preventSelection);
    };
  }, []);

  return null;
};

export default TextSelectionBlocker;