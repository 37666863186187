import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { logoutService, logoutAdminService } from '../services/authServices';

interface User {
  id: string;
  nombre: string;
  apellidoPaterno: string;
  email: string;
}

interface Admin {
  id: string;
  nombre: string;
  apellidoPaterno: string;
  email: string;
}

interface AuthContextType {
  user: User | null;
  admin: Admin | null;
  token: string | null;
  adminToken: string | null;
  loginUser: (token: string, user: User) => void;
  loginAdmin: (token: string, admin: Admin) => void;
  logoutUser: () => void;
  logoutAdmin: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [adminToken, setAdminToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Load user and admin from localStorage
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    const storedAdmin = localStorage.getItem('admin');
    const storedAdminToken = localStorage.getItem('adminToken');

    if (storedUser && storedToken) {
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
    }

    if (storedAdmin && storedAdminToken) {
      setAdmin(JSON.parse(storedAdmin));
      setAdminToken(storedAdminToken);
    }

    setLoading(false); 
  }, []);

  const loginUser = (token: string, user: User) => {
    setUser(user);
    setToken(token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
  };

  const loginAdmin = (token: string, admin: Admin) => {
    setAdmin(admin);
    setAdminToken(token);
    localStorage.setItem('admin', JSON.stringify(admin));
    localStorage.setItem('adminToken', token);
  };

  const logoutUser = () => {
    if (user) {
      logoutService(user.id);
    }
    setUser(null);
    setToken(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  const logoutAdmin = () => {
    if (admin) {
      logoutAdminService(admin.id);
    }
    setAdmin(null);
    setAdminToken(null);
    localStorage.removeItem('admin');
    localStorage.removeItem('adminToken');
  };

  if (loading) return null;

  return (
    <AuthContext.Provider
      value={{
        user,
        admin,
        token,
        adminToken,
        loginUser,
        loginAdmin,
        logoutUser,
        logoutAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
