import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Examen, Modulo } from "../../interfaces/IExam";

interface ExamDetailProps {
  modules: Modulo[];
  startExam: () => void;
  exam: Examen;
}

const ExamDetail: React.FC<ExamDetailProps> = ({
  modules,
  startExam,
  exam,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{ minWidth: "80%", margin: "0 auto", padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          component="h2"
          gutterBottom
        >
          {exam.nombre}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {exam.descripcion}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 4 }}>
        Tiempo límite: {exam.tiempoLimite} minutos
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell>Módulos</TableCell>
                <TableCell>Preguntas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modules.map((modulo) => (
                <TableRow
                  key={modulo.nombreModulo}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {modulo.nombreModulo}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {modulo.preguntas.length}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="contained"
          color="secondary"
          sx={{ marginTop: 2 }}
          onClick={handleClickOpen}
        >
          Comenzar examen
        </Button>
      </CardContent>

      {/* Alert Modal */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          Antes de comenzar el examen
        </DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          <Typography variant="body1" color="textSecondary">
            Antes de comenzar, asegúrate de estar en un lugar tranquilo y sin distracciones. Recuerda que el examen tiene 
            una duración de {exam.tiempoLimite} minutos y no podrás reiniciarlo una vez iniciado. ¿Deseas continuar?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={startExam}
            variant="outlined"
            color="primary"
            sx={{ ml: 1 }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ExamDetail;
