import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { fetchExamsService, fetchModules, iniciarExamen } from "../../services/examServices";
import { Examen, ResponseModule } from "../../interfaces/IExam";
import { CircularProgress } from "@mui/material";
import ExamDetail from "./ExamDetail";
import Questionnaire from "../Questionarie/Questionarie";
import ExamCard from "../Cards/ExamCard";
import { useAuth } from "../../context/AuthContext";

const DashboardExams: React.FC = () => {
  const [selectedExam, setSelectedExam] = useState<Examen>();
  const [module, setModules] = useState<ResponseModule>();
  const [examenes, setExams] = useState<Examen[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isExamStarted, setIsExamStarted] = useState<boolean>(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user) fetchExams(user?.id);
  }, []);
  const startExam = () => {
    if(user && selectedExam)
    iniciarExamen(user?.id, selectedExam?.id).then( () => {
      setIsExamStarted(true);

    });
  };
  const loadModuleData = async (ex: Examen) => {
    setLoading(true);
    setSelectedExam(ex);
    try {
      if(user){
      const exams = await fetchModules(ex.id, user?.id);
      setModules(exams);
    }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchExams = async (uid: string) => {
    if (!user) return;
    setLoading(true);
    console.log(user)
    try {
      const exams = await fetchExamsService(uid);
      setExams(exams);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress color="primary" />;
  }
  return (
    <>
      {selectedExam ? (
        <>
          {isExamStarted ? (
            <Card
              sx={{
                minWidth: "80%",
                minHeight: "80vh",
                margin: "0 auto",
                padding: 2,
              }}
            >
              <CardContent>
                {module && (               
                   <Questionnaire dataModule={module} exam={selectedExam} />
                  )}
              </CardContent>
            </Card>
          ) : (
            <>
            {module?.modulos && (               
            <ExamDetail modules={module?.modulos} startExam={startExam} exam={selectedExam} />
            )}
          </>
          )}
        </>
      ) : (
        <>
          <CardContent>
            <Typography
              variant="h6"
              fontWeight="bold"
              component="h2"
              gutterBottom
            >
              Exámenes inscritos
            </Typography>
            {examenes.map((examen) => (
              <ExamCard
                key={"card-" + examen.id}
                examen={examen}
                callback={() => loadModuleData(examen)}
                isDetail={true}
              />
            ))}
          </CardContent>
        </>
      )}
    </>
  );
};

export default DashboardExams;
