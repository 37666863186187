import React, { useState } from "react";
import {
  TablePagination,
  Paper,
  Box,
  Stack,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  actionButton,
  EstudianteEstatus,
  EstudianteExamen,
  ExamenResponse,
} from "../../../interfaces/IMonitoreo";
import useFetchData from "../../../hooks/services";
import { updateStatusById } from "../../../services/monitoreoServices";
import { Table, TableBody, TableCell, TableHead, TablePaper, TableRow } from "../../Table/CustomeTable";
import { formatTimeDifference } from "../../../utils/utils";

interface ParticipantsTableProps {
  initialEstudiantes: EstudianteExamen[];
  searchTerm: string;
}
const ParticipantsTable: React.FC<ParticipantsTableProps> = ({
  initialEstudiantes,
  searchTerm,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [estudiantes, setEstudiantes] =
    useState<EstudianteExamen[]>(initialEstudiantes);
  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<actionButton | null>();
  const [selectedId, setSelectedId] = useState<string | null>();

  const handleOpen = (action: actionButton, id: string) => {
    setSelectedAction(action);
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAction(null);
    setSelectedId(null);
  };

  const confirmAction = () => {
    if (selectedAction && selectedId) {
      handleButtonClick(selectedAction, selectedId);
    }
    handleClose();
  };
  const filteredEstudiantes = estudiantes?.filter((estudiante) => {
    const nombre = estudiante.estudiante.nombre.toLowerCase();
    const email = estudiante.estudiante.email.toLowerCase();
    const searchLower = searchTerm?.toLowerCase() || "";
    console.log(searchLower+" "+nombre+" "+email)
    return nombre.includes(searchLower) || email.includes(searchLower);
  });

  const handleButtonClick = async (
    action: actionButton,
    examenUsuarioId: string
  ) => {
    setLoading(true);
    setError("");

    try {
      const response = await updateStatusById(examenUsuarioId, action);
      const updatedExam = response.examen;

      setEstudiantes((prevEstudiantes) =>
        prevEstudiantes.map((estudiante) =>
          estudiante.examenUsuarioId === updatedExam.id
            ? { ...estudiante, estatus: updatedExam.estatus }
            : estudiante
        )
      );
    } catch (err) {
      setError("Error updating exam status.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const paginatedEstudiantes = filteredEstudiantes.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusColor = (status: EstudianteEstatus) => {
    switch (status) {
      case "completado":
        return "#4CAF50";
      case "pendiente":
        return "#9E9E9E";
      case "en_progreso":
        return "#2196F3";
      case "pausado":
        return "#FF9800";
      case "detenido":
        return "#F44336";
    }
  };

  return (
    <>
      <TablePaper>
          <Table>
            <TableHead color="#111">
              <TableRow>
              <TableCell colSpan={2}>Nombre del sustentante</TableCell>
                <TableCell>Estatus</TableCell>
                <TableCell>Correo electrónico</TableCell>
                <TableCell>
                  <Typography style={{ whiteSpace: "pre-wrap", fontSize:14 }}>
                    Preguntas{"\n"}Respondidas
                  </Typography>
                </TableCell>     
                <TableCell>Tiempo</TableCell>           
<TableCell colSpan={3} align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedEstudiantes.map((estudiante) => (
                <TableRow key={estudiante.examenUsuarioId}>
                  <TableCell colSpan={2}>
                    {estudiante.estudiante.nombre}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex", 
                        alignItems: "center", 
                      }}
                    >
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          backgroundColor: getStatusColor(estudiante.estatus),
                          display: "inline-block",
                          marginRight: 1, 
                        }}
                      />
                      <div>
                      {estudiante.estatus
                      .replace("_", " ") // Reemplaza el guion bajo con espacio
                      .toLowerCase() // Asegúrate de que todo esté en minúsculas primero
                      .replace(/^\w/, (c) => c.toUpperCase()) // Convierte la primera letra en mayúscula
                      }
                      </div>
                    </Box>
                  </TableCell>
                  
                  <TableCell>
                    {estudiante.estudiante.email}
                  </TableCell>
                  <TableCell>{estudiante.preguntasRespondidas}</TableCell>
                  <TableCell>{formatTimeDifference(estudiante.fechaFin)}</TableCell>
                  <TableCell colSpan={3}>
                    <Stack direction="row" spacing={1} justifyContent="center">
                      <Button
                        onClick={() =>
                          handleOpen("continuar", estudiante.examenUsuarioId)
                        }
                        variant="contained"
                        sx={{ backgroundColor: "#4CAF50", color: "white" }}
                        disabled={
                          estudiante.estatus === "en_progreso" ||
                          estudiante.estatus === "pendiente" ||
                          estudiante.estatus === "detenido" ||
                          estudiante.estatus === "completado"
                        }
                      >
                        Continuar
                      </Button>
                      <Button
                        onClick={() =>
                          handleOpen("pausa", estudiante.examenUsuarioId)
                        }
                        variant="contained"
                        sx={{ backgroundColor: "#FF9800", color: "white" }}
                        disabled={
                          estudiante.estatus === "pausado" ||
                          estudiante.estatus === "pendiente" ||
                          estudiante.estatus === "detenido" ||
                          estudiante.estatus === "completado"
                        }
                      >
                        Pausa
                      </Button>

                      <Button
                        onClick={() =>
                          handleOpen("cerrar", estudiante.examenUsuarioId)
                        }
                        variant="contained"
                        sx={{ backgroundColor: "#F44336", color: "white" }}
                        disabled={
                          estudiante.estatus === "detenido" ||
                          estudiante.estatus === "completado"
                        }
                      >
                        Terminar
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        <TablePagination
          component="div"
          count={filteredEstudiantes.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página"
        />
      </TablePaper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmar Acción</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas realizar esta acción? Esta acción no
            puede deshacerse.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmAction} color="primary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ParticipantsTable;
