// src/components/PrintBlocker.tsx
import { useEffect } from 'react';

const PrintBlocker: React.FC = () => {
  useEffect(() => {
    const mediaQueryList = window.matchMedia('print');

    const handlePrint = (event: MediaQueryListEvent) => {
      if (event.matches) {
        alert('La impresión está deshabilitada.');
        setTimeout(() => {
          window.stop(); 
        }, 0);
      }
    };

    mediaQueryList.addEventListener('change', handlePrint);
    return () => {
      mediaQueryList.removeEventListener('change', handlePrint);
    };
  }, []);

  return null; 
};

export default PrintBlocker;
