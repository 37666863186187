import { styled, Theme } from "@mui/material/styles";
import TableMUI from "@mui/material/Table";
import TableBodyMUI from "@mui/material/TableBody";
import TableCellMUI from "@mui/material/TableCell";
import TableHeadMUI from "@mui/material/TableHead";
import TableRowMUI from "@mui/material/TableRow";
import PaperMUI from "@mui/material/Paper";
import { Typography, Box } from "@mui/material";

/**
 * TablePaper
 */
export const TablePaper = styled(PaperMUI)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 2, // Rounded corners for the whole table
    overflow: "hidden", // Prevent content overflow with rounded corners
  }));

/**
 * Table
 */
export const Table = styled(TableMUI)(({ theme }) => ({
}));

/**
 * TableBody
 */
export const TableBody = styled(TableBodyMUI)(({ theme }) => ({
  fontFamily: "'Helvetica', 'Arial', sans-serif",
}));

/**
 * TableCell
 */
export const TableCell = styled(TableCellMUI)(({ theme, color }) => ({
  "&.MuiTableCell-head": {
    color: theme.palette.primary.contrastText,
    backgroundColor: color?? "#2196F3",
    fontSize: "1rem",
  },
}));

/**
 * TableHead
 */
export const TableHead = styled(TableHeadMUI)(({ theme,color }) => ({
  "&.MuiTableCell-head": {
    color: theme.palette.primary.contrastText,
    backgroundColor: color?? "#111",
    fontSize: "1rem",
  },
}));

/**
 * TableRow
 */
export const TableRow = styled(TableRowMUI)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[100],
  },
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
  "&.MuiTableRow-head": {
    height: theme.spacing(3),
  },
}));
