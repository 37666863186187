import axios from "axios";

export const getExamCompleteReport = async (examenId: string) => {
  const response = await axios.get(`https://backexams-624915669123.us-central1.run.app/admin/report/evaluacion/condensado/excel/${examenId}`, {
    responseType: "blob", 
  });
  return response.data;
};
export const getExamPsicometricoReport = async (examenId: string) => {
  const response = await axios.get(`https://backexams-624915669123.us-central1.run.app/admin/report/evaluacion/psicometrico/excel/${examenId}`, {
    responseType: "blob", 
  });
  return response.data;
};
export const getIndividualReport = async (examStudentId: string) => {
  const response = await axios.get(`https://backexams-624915669123.us-central1.run.app/admin/report/evaluacion/medico/excel/${examStudentId}`, {
    responseType: "blob", 
  });
  return response.data;
};
