import { AdminModulo, Examen, Modulo, Pregunta, ResponseModule } from "../interfaces/IExam";

import axios from 'axios';

export const fetchExamsService = async (idUsuario: string): Promise<Examen[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/api/exams/pendientes/usuarios/'+idUsuario);
  return response.data;
};

export const fetchModules = async (examId: number, userId: string): Promise<ResponseModule> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/api/exams/generar/'+examId+'?usuarioId='+userId);
  return response.data;
}; 

export const getAvailableExams = async (): Promise<Examen[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/api/exams/disponibles');
  return response.data;
};
  
export const getFinishedExams = async (userId: string): Promise<Examen[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/api/exams/terminados/usuarios/'+userId);
  return response.data;
};
  

export const inscribirExamen = async (usuarioId: string, examenId: number)  => {
  console.log("ENTRA INSCRIBIR EXAMEN");
  try {
     const response = await axios.post<any>('https://backexams-624915669123.us-central1.run.app/api/exams/inscribir', 
      {
        usuarioId: usuarioId,
        examenId: examenId
      }
     );
     return response.data;

  } catch (error: any) {
      throw new Error(error);
  }
};

export const guardarRespuestasExamen = async (usuarioId: string, examenId: number, examResult: any)  => {
  console.log("ENTRA <SUBIR RESPUESTAS> EXAMEN");
  try {                                 
     await axios.post(`https://backexams-624915669123.us-central1.run.app/api/answers/guardarrespuestas/exam/${examenId}/usuario/${usuarioId}`, examResult)
     .then(response => {
       console.log("Exam submitted:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
};
export const iniciarExamen = async (usuarioId: string, examenId: number) => {
  const examResult = {
    respuestas: [],
    estatus: "en_progreso",
  };
  console.log("ENTRA <INICIAR EXAMEN> ");
  try {                                 
     await axios.post(`https://backexams-624915669123.us-central1.run.app/api/answers/guardarrespuestas/exam/${examenId}/usuario/${usuarioId}`, examResult)
     .then(response => {
       console.log("Exam started:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
}
export const terminarExamen = async (usuarioId: string, examenId: number)  => {
  console.log("ENTRA <TERMINAR> EXAMEN");
  try {
     await   axios.put(`https://backexams-624915669123.us-central1.run.app/api/answers/terminar/exam/${examenId}/usuario/${usuarioId}`)
     .then(response => {
       console.log("Exam submitted:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
};

//-----------------ADMIN EXAMS-----------------//
export const getAdminExams = async (): Promise<Examen[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/admin/exams/examenes');
  return response.data;
};

export const updateExam = async (idExamen: number, updatedData: any) => {
  const url = 'https://backexams-624915669123.us-central1.run.app/admin/exams/'+idExamen;
  const response = await axios.patch(url, updatedData, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

export const updateModule = async (idModulo: string, updatedData: any) => {  console.log(updatedData);
  console.log("ID MODULO "+idModulo);

  console.log(updatedData);
  const url = 'https://backexams-624915669123.us-central1.run.app/admin/exams/modulo/'+idModulo;
  console.log("URL "+url);

  const response = await axios.patch(url, updatedData, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

export const getAdminModules = async ( examenId: string ): Promise<AdminModulo[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/admin/exams/modulos/'+examenId);
  return response.data;
};
export const getAdminQuestions = async ( examenId: string ): Promise<Pregunta[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/admin/exams/preguntas/'+examenId);
  return response.data;
};
