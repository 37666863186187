import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AdminModulo, Examen } from "../../../interfaces/IExam";
import ExamenForm from "./ExamenForm";
import ModuleForm from "./ModulesForm";
import QuestionsForm from "./QuestionsForm";  
import useFetchData from "../../../hooks/services";
import { getAdminModules, getAdminQuestions } from "../../../services/examServices";
import { Box, Typography } from "@mui/material";

const ExamenEdit = () => {
  const location = useLocation();
  const examen: Examen = location.state?.exam;
  const { data: modulos, loading, error } = useFetchData<AdminModulo[]>(() => getAdminModules(examen.id.toString()), []);
  const [questions, setQuestions] = useState<any[]>([]);

  const handleSaveModules = (updatedModules: AdminModulo[]) => {
    console.log(updatedModules);
  };

  const handleQuestionsFetched = (fetchedQuestions: any[]) => {
    setQuestions(fetchedQuestions); 
  };

  return (
    <Box width="100%" margin="auto">
        <Box sx={{width: '80%' }}>
    <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom sx={{mt:1, mb: 1}}>
      Editar Examen
    </Typography>
  </Box>
      <ExamenForm examen={examen} />
      <ModuleForm
        modulos={modulos || []}
        onSave={handleSaveModules}
      />
      
    </Box>
  );
};
export default ExamenEdit;
