import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { Outlet, useNavigate } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TimelapseIcon from '@mui/icons-material/Timelapse';

import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import logo from '../../assets/img/logo.png';

export default function DashboardControl() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const { admin, logoutAdmin } = useAuth();
  const drawerWidth = 240;

  const navigationItems = [
    {
      text: 'Examenes',
      icon: <ArticleIcon />,
      onClick: () => navigate("/control/exams"),
    },
    {
      text: 'Monitoreo',
      icon: <TimelapseIcon />,
      onClick: () => navigate("/control/monitoreo"),
    },
    {
      text: 'Reportes',
      icon: <AssessmentIcon />,
      onClick: () => navigate("/control/reportes"),
    },
    {
      text: 'Sustentantes',
      icon: <GroupsIcon />,
      onClick: () => navigate("/control/sustentantes"),
    },
    {
      text: 'Usuarios',
      icon: <PeopleIcon />,
      onClick: () => navigate("/control/usuarios"),
    }
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar 
        position="fixed" 
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'white',
          color: 'primary.main'
        }}
      >
        <Toolbar>
          <Box
            component="img"
            src={logo}
            alt="App Logo"
            sx={{ height: 40, mr: 2 }}
          />
          <Typography 
            variant="h6" 
            sx={{ flexGrow: 1, color: 'primary.main', fontWeight: 'bold' }}
          >
            ExamSys Control
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
            <IconButton onClick={handleMenuOpen} sx={{ height: 30, width: 30 }}>
              <Avatar alt="User Profile" sx={{ width: 32, height: 32, bgcolor: 'primary.main' }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: { 
                  width: 220, 
                  padding: 2, 
                  borderRadius: 2,
                  boxShadow: 3,
                  bgcolor: 'white',
                  color: 'primary.main'
                }
              }}
            >
              {admin && (
                <Box sx={{ mb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    {admin.nombre} {admin.apellidoPaterno}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {admin.email}
                  </Typography>
                </Box>
              )}
              
              <Divider sx={{ my: 1, bgcolor: 'primary.main' }} />
              
              <MenuItem onClick={logoutAdmin} sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Cerrar sesión
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { 
            width: drawerWidth, 
            boxSizing: 'border-box',
            bgcolor: 'primary.main',
            color: 'white'
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {navigationItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={item.onClick} sx={{ '&:hover': { bgcolor: 'primary.dark' } }}>
                  <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} sx={{ color: 'white' }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider sx={{ bgcolor: 'white' }} />
        </Box>
      </Drawer>
      
      <Box component="main" sx={{ flexGrow: 1, p: 3, bgcolor: 'white', color: 'primary.main' }}>
        <Toolbar />
        <Outlet/>
      </Box>
    </Box>
  );
}
