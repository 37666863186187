import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from "@mui/material";
import { WarningRounded } from "@mui/icons-material"; // Warning icon from MUI icons

interface PausedDialogProps {
  open: boolean;
  onClose: () => void;
}

const PausedDialog: React.FC<PausedDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <WarningRounded sx={{ marginRight: 2, color: "orange" }} />
          <Typography variant="h6">Examen Pausado</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
       
        <Typography variant="body1">
          Por favor, comunícate con tu examinador o espera unos momentos mientras se soluciona la situación.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Importante:</strong> No cierres la ventana ni actualices la página, ya que el examen podría perderse.
        </Typography>
      </DialogContent>
      <DialogActions>
       
      </DialogActions>
    </Dialog>
  );
};

export default PausedDialog;
