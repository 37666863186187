// src/App.tsx
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardL from './pages/DashboardLayout';
import DashboardWelcome from './components/Dashboard/DashboardWelcome';
import DashboardExams from './components/Dashboard/DashboardExams';
import InscribirExamen from './components/Dashboard/InscribirExamen';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRouteAdmin, ProtectedRouteUser} from './components/ProtectedRoute';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import SecurityLayer from './components/SecurityLayer'; // Importar el componente SecurityLayer
import PrintBlocker from './components/PrintBlocker'; // Importar PrintBlocker
import TextSelectionBlocker from './components/TextSelectionBlocker'; // Importar TextSelectionBlocker
import Admin from './pages/Admin/Admin';
import DashboardControl from './pages/Admin/DashboardControl';
import ControlExams from './components/DashboardControl/ControlExams';
import ControlMonitoreo from './components/DashboardControl/ControlMonitoreo';
import ControlSustentante from './components/DashboardControl/ControlSustentante';
import ControlReporte from './components/DashboardControl/ControlReporte';
import MonitoreoDetail from './components/DashboardControl/Monitoreo/MonitoreoDetail';
import ExamenEdit from './components/DashboardControl/Examenes/ExamenEdit';
import ShopPage from './pages/Shop';
import { Examen } from './interfaces/IExam';
import SustentanteEdit from './components/DashboardControl/Sustentantes/SustentantesEdit';
import ControlUsuarios from './components/DashboardControl/ControlUsuarios';
import UsuariosEdit from './components/DashboardControl/Usuarios/UsuariosEdit';
import CheckoutPage from './pages/Checkout';
import ShopLayout from './pages/ShopLayout';

const App: React.FC = () => {
  const [cart, setCart] = useState<Examen[]>([]);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <SecurityLayer />
          <PrintBlocker />
          <TextSelectionBlocker/>
          <Routes>
            {/* Rutas Públicas */}
            <Route path="/" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route element={<ShopLayout cart={cart} />}>
              <Route path="/shop" element={<ShopPage cart={cart} setCart={setCart} />} />
              <Route path="/checkout" element={<CheckoutPage cart={cart} setCart={setCart} />} />
            </Route>
            <Route path="/register" element={<Register />} />

            {/* Rutas Protegidas */}
            <Route element={<ProtectedRouteAdmin />}>
              <Route path="/control" element={<DashboardControl />}>
                <Route index element={<Navigate to="exams" replace />} />
                <Route path="exams" element={<ControlExams />} />
                <Route path="exams/:id" element={<ExamenEdit />} />
                <Route path="monitoreo" element={<ControlMonitoreo />} />
                <Route path="monitoreo/:id" element={<MonitoreoDetail />} />
                <Route path="reportes" element={<ControlReporte />} />
                <Route path="sustentantes" element={<ControlSustentante />} />
                <Route path="sustentantes/:id" element={<SustentanteEdit />} />
                <Route path="usuarios" element={<ControlUsuarios />} />
                <Route path="usuarios/:id" element={<UsuariosEdit />} />
              </Route>
            </Route>

            <Route element={<ProtectedRouteUser />}>
              <Route path="/dashboard" element={<DashboardL />}>
                <Route index element={<DashboardWelcome />} />
                <Route path="examenes" element={<DashboardExams />} />
                <Route path="resultados" element={<DashboardExams />} />
                <Route path="inscribir" element={<InscribirExamen />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
