import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Box,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import useFetchData from "../../hooks/services";
import { getAdminExams } from "../../services/examServices";
import { Examen } from "../../interfaces/IExam";
import { Table, TableBody, TableCell, TableHead, TablePaper, TableRow } from "../Table/CustomeTable";

import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const ControlExams: React.FC = () => {
  const { data: examenes, loading, error } = useFetchData(() => getAdminExams(), []);
  const navigate = useNavigate();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        Ocurrió un error al cargar los exámenes.
      </Typography>
    );
  }

  return (
    <CardContent>
      <Grid container  sx={{ marginBottom: 5}} alignItems="center">
  <Box sx={{width: '80%' }}>
    <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
      Exámenes
    </Typography>
  </Box>
{/*   <Box sx={{width: '20%' }}>
    <Button
      onClick={() => {}}
      variant="contained"
      color="secondary"
    >
      Subir examen
    </Button>
  </Box> */}
</Grid>



      <TablePaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Nombre</strong></TableCell>
              <TableCell><strong>Fecha aplicación</strong></TableCell>
              <TableCell><strong>Tiempo limite</strong></TableCell>
              <TableCell><strong>Estatus</strong></TableCell>
              <TableCell><strong>Acciones</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examenes?.map((examen: Examen) => (
              <TableRow key={examen.id}>
                <TableCell>{examen.id}</TableCell>
                <TableCell>{examen.nombre}</TableCell>
                <TableCell>
                  {examen.fechaAplicacion ? 
                    format(new Date(examen.fechaAplicacion), "dd'/'MMM'/'yyyy '-' hh:mm a", { locale: es })
                    : 'Fecha no disponible'}
                </TableCell>
                <TableCell>{examen.tiempoLimite } min</TableCell>
                <TableCell>{examen.activo? "Activo": "Inactivo"} </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      navigate(`/control/exams/${examen.id}`, { state: { exam: examen } })
                    }
                  >
                    Ver detalles
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TablePaper>
    </CardContent>
  );
};

export default ControlExams;
