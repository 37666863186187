import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Examen } from "../interfaces/IExam";
import useFetchData from "../hooks/services";
import { getAdminExams } from "../services/examServices";
import Examen1 from "../assets/img/exam-1.png";

interface ShopPageProps {
  cart: Examen[];
  setCart: React.Dispatch<React.SetStateAction<Examen[]>>;
}

const ShopPage: React.FC<ShopPageProps> = ({ cart, setCart }) => {
  const { data: mockExams, loading, error } = useFetchData(() => getAdminExams(), []);

  const [selectedExam, setSelectedExam] = useState<Examen | null>(null);

  const handleAddToCart = (exam: Examen) => {
    if (!cart.find((item) => item.id === exam.id)) {
      setCart([...cart, exam]);
    }
  };

  const handleOpenDialog = (exam: Examen) => {
    setSelectedExam(exam);
  };

  const handleCloseDialog = () => {
    setSelectedExam(null);
  };

  return (
    <Box padding={2}>
      {/* Header Section */}
      <Box marginBottom={2} textAlign="center">
        <Typography variant="h4" gutterBottom>
            Convocatorias
        </Typography>
        <Typography variant="subtitle1">
          Selecciona una de nuestras convocatorias y registrate para poder acceder a nuestras certificaciones.
        </Typography>
      </Box>

      {/* Cards Section */}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-evenly"
        paddingLeft={20}
        paddingRight={20}
        gap={4}
        alignItems="stretch"
      >
        {mockExams?.map((exam) => (
         <Box
         key={exam.id}
         flex="1 1 calc(33.333% - 32px)" 
         maxWidth="calc(33.333% - 32px)" 
         minWidth="300px" 
         display="flex"
         flexDirection="column" 
         onClick={() => {
           handleOpenDialog(exam);
         }}
         sx={{
           cursor: "pointer",
           "&:hover": {
             boxShadow: 4, 
           },
         }}
       >
         <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
           <CardMedia
             component="img"
             height="240"
             image={Examen1} 
             alt={exam.nombre}
           />
           <CardContent sx={{ flexGrow: 1 }}>
             <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
               {exam.nombre}
             </Typography>
             {/* <Typography variant="body2" color="textSecondary" gutterBottom noWrap>
               {exam.descripcion}
             </Typography> */}
           </CardContent>
         </Card>
       </Box>
        ))}
      </Box>

      {/* Dialog Section */}
      <Dialog open={!!selectedExam} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
  <DialogTitle>
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
      {selectedExam?.nombre}
    </Typography>
  </DialogTitle>
  <DialogContent>
    <Box display="flex" alignItems="flex-start" gap={2}>
      {/* Image Section */}
      <Box flexShrink={0}>
        <CardMedia
          component="img"
          sx={{
            width: 150, // Square dimensions
            height: 150,
            borderRadius: 2, // Optional rounded corners
          }}
          image={Examen1} // Placeholder image
          alt={selectedExam?.nombre}
        />
      </Box>
      {/* Content Section */}
      <Box flex="1">
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {selectedExam?.descripcion}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', color: 'primary.main', marginTop: 2 }}
        >
          $9,999.99
        </Typography>
      </Box>
    </Box>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="secondary">
      Close
    </Button>
    <Button
      onClick={() => {
        if (selectedExam) handleAddToCart(selectedExam);
        handleCloseDialog();
      }}
      color="primary"
      variant="contained"
    >
      Agregar
    </Button>
  </DialogActions>
</Dialog>

    </Box>
  );
};

export default ShopPage;
