import React, { useState } from "react";
import {
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid2";
import useFetchData from "../../hooks/services";
import { getAdminExams } from "../../services/examServices";
import { Examen } from "../../interfaces/IExam";
import { getExamCompleteReport, getExamPsicometricoReport, getIndividualReport } from "../../services/reportServices";
import { getExamStudents } from "../../services/monitoreoServices";
import { EstudianteExamen } from "../../interfaces/IMonitoreo";
import ReportStudentDialog from "../Dialog/ReportStudentDialog";

const ControlReporte: React.FC = () => {
  const { data: examenes, loading, error } = useFetchData(() => getAdminExams(), []);
  const [students, setStudents] = useState<EstudianteExamen[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<EstudianteExamen | null>();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        Ocurrió un error al cargar los exámenes.
      </Typography>
    );
  }

  const handleDownload = async (examId: string, isPsicometrico?: boolean) => {
    try {
      var response;
      if(isPsicometrico)
         response = await getExamPsicometricoReport(examId);
        else
       response = await getExamCompleteReport(examId);

      const blob = new Blob([response], { type: "application/xls" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `exam_${examId}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  const handleIndividual = async (id: string) => {
    try {
      const examStudents = await getExamStudents(id);
      setStudents(examStudents.estudiantes);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };



  const handleCloseModal = () => {
    setOpenModal(false);
    setStudents([]);
    setSelectedStudent(null);
  };

  return (
    <CardContent>
      <Grid container sx={{ marginBottom: 2 }} alignItems="center">
        <Box sx={{ width: "80%" }}>
          <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
            Exámenes
          </Typography>
        </Box>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Nombre</strong></TableCell>
              <TableCell><strong>Psicometrico</strong></TableCell>
              <TableCell><strong>Condensado</strong></TableCell>
              <TableCell><strong>Individual</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examenes?.map((examen: Examen) => (
              <TableRow key={examen.id}>
                <TableCell>{examen.id}</TableCell>
                <TableCell>{examen.nombre}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(examen.id.toString(), true)}
                  >
                    Descargar
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(examen.id.toString())}
                  >
                    Descargar
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleIndividual(examen.id.toString())}
                  >
                    Individual
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}

      <ReportStudentDialog       openModal={openModal}
  handleCloseModal ={handleCloseModal} 
  students={students}/>
   
    </CardContent>
  );
};

export default ControlReporte;
