import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Divider,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Examen } from "../interfaces/IExam";
import Examen1 from "../assets/img/exam-1.png";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

interface CheckoutPageProps {
  cart: Examen[];
  setCart: React.Dispatch<React.SetStateAction<Examen[]>>;
}

const CheckoutPage: React.FC<CheckoutPageProps> = ({ cart, setCart }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false); // For dialog state
  const { user } = useAuth();
  const navigate = useNavigate();

  const [cardDetails, setCardDetails] = useState({
    name: "",
    cardNumber: "",
    cvc: "",
    expiryMonth: "",
    expiryYear: "",
  });

  const handleRemoveFromCart = (id: number) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCardDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handlePayment = async () => {
    if (!user) {
      setOpenDialog(true);
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Initialize Conekta (Ensure you replace this with your public key)
      const conekta = (window as any).Conekta;
      conekta.setPublicKey("your_public_key_here");

      // Tokenize card
      conekta.Token.create(
        {
          card: {
            name: cardDetails.name,
            number: cardDetails.cardNumber,
            cvc: cardDetails.cvc,
            exp_month: cardDetails.expiryMonth,
            exp_year: cardDetails.expiryYear,
          },
        },
        (token: any) => {
          console.log("Token created:", token);

          // Send token and cart data to your backend to process the payment
          processPayment(token.id);
        },
        (error: any) => {
          console.error("Conekta error:", error);
          setError("Failed to process payment. Please check your details.");
          setLoading(false);
        }
      );
    } catch (err) {
      console.error("Payment error:", err);
      setError("An unexpected error occurred.");
      setLoading(false);
    }
  };

  const processPayment = async (tokenId: string) => {
    try {
      const response = await fetch("/api/payments", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: tokenId,
          cart,
          amount: cart.reduce((total, item) => total + 50, 0),
        }),
      });

      if (response.ok) {
        setSuccess("Payment successful! Thank you for your purchase.");
        setCart([]);
        localStorage.removeItem("cart");
      } else {
        const { message } = await response.json();
        setError(message || "Failed to process payment.");
      }
    } catch (err) {
      console.error("Backend error:", err);
      setError("Payment failed. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box padding={4}>
      <Typography variant="h4" gutterBottom>
        Resumen de compra
      </Typography>

      {/* Cart Summary */}
      {cart.length > 0 ? (
        <List>
          {cart.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem>
                <img width={60} height={60} src={Examen1} alt={item.nombre} />
                <ListItemText
                  sx={{ marginLeft: 2 }}
                  primary={item.nombre}
                  secondary={`Descripción: ${item.descripcion}`}
                />

                <Typography variant="body1">$50</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRemoveFromCart(item.id)}
                  style={{ marginLeft: "1rem" }}
                >
                  Remover
                </Button>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Typography variant="body1">Tu carrito está vacío.</Typography>
      )}

      {/* Payment Form */}
      {cart.length > 0 && (
        <Box marginTop={4}>
          <Typography variant="h6" gutterBottom>
            Detalle de pagos
          </Typography>
          <TextField
            fullWidth
            label="Nombre en la tarjeta"
            name="name"
            value={cardDetails.name}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Número de tarjeta"
            name="cardNumber"
            value={cardDetails.cardNumber}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="CVC"
            name="cvc"
            value={cardDetails.cvc}
            onChange={handleInputChange}
            margin="normal"
          />
          <Box display="flex" gap={2}>
            <TextField
              fullWidth
              label="Mes que expira(MM)"
              name="expiryMonth"
              value={cardDetails.expiryMonth}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Año que expira (YYYY)"
              name="expiryYear"
              value={cardDetails.expiryYear}
              onChange={handleInputChange}
              margin="normal"
            />
          </Box>

          {error && <Alert severity="error" sx={{ marginTop: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ marginTop: 2 }}>{success}</Alert>}

          <Box marginTop={4} textAlign="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={loading || cart.length === 0}
                onClick={handlePayment}
              >
                Pagar
              </Button>
            )}
          </Box>
        </Box>
      )}

      {/* Dialog for User Action */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Inicia sesión o regístrate</DialogTitle>
        <DialogContent>
          <Typography>
            Para completar la compra, necesitas iniciar sesión o registrarte.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/")} color="primary">
            Iniciar sesión
          </Button>
          <Button onClick={() => navigate("/register")} color="secondary">
            Registrarme
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CheckoutPage;
