import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import ParticipantsTable from "./ParticipantsTable";
import ExamInfoBox from "../../Cards/ExamInfoBox";
import { useLocation, useParams } from "react-router-dom";
import useFetchData from "../../../hooks/services";
import { getExamStudents } from "../../../services/monitoreoServices";
import { ExamenMonitoreo } from "../../../interfaces/IMonitoreo";

const MonitoreoDetail = () => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const examName = location.state?.examName; 
  
  const {
    data: examenes,
    loading,
    error,
  } = useFetchData<ExamenMonitoreo>(() => getExamStudents(id ?? ""), []);

  if (loading) {
    return <CircularProgress color="primary" />;
  }

  const filteredEstudiantes = examenes?.estudiantes?.filter((estudiante) => {
    const nombre = estudiante.estudiante.nombre.toLowerCase();
    const email = estudiante.estudiante.email.toLowerCase();
    const searchLower = searchTerm?.toLowerCase() || "";
    console.log(searchLower+" "+nombre+" "+email)
    return nombre.includes(searchLower) || email.includes(searchLower);
  });
  

  return (
    <Box sx={{ mb: 5}}>
      <ExamInfoBox onSearchChange={setSearchTerm} examName={examName}/>
      <Box sx={{ maxHeight: "60vh" }}>
        <ParticipantsTable
          initialEstudiantes={filteredEstudiantes ?? []}
          searchTerm={searchTerm}
        />
      </Box>
    </Box>
  );
};

export default MonitoreoDetail;
