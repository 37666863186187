export const formatTimeDifference = (date: string): string => {
    const targetDate = new Date(date);
    const currentDate = new Date();
  
    // Calculate the difference in milliseconds
    const timeDifference = targetDate.getTime() - currentDate.getTime();
  
    // If the difference is negative, return 0
    if (timeDifference <= 0) {
      return "00:00";
    }
  
    // Convert milliseconds to minutes and seconds
    const totalSeconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
  
    // Format as 00:00min
    const formattedTime = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return formattedTime;
  };