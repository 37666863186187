import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export const ProtectedRouteUser: React.FC = () => {
  const { user, admin } = useAuth();

  if (admin || !user) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export const ProtectedRouteAdmin: React.FC = () => {
  const { user, admin } = useAuth();

  if (user || !admin) {
    return <Navigate to="/admin" replace />;
  }

  return <Outlet />;
};
