// src/components/SecurityLayer.tsx
import { useEffect } from 'react';

const SecurityLayer: React.FC = () => {
  useEffect(() => {
    // 1. Bloquear menú contextual (clic derecho)
    const disableContextMenu = (event: MouseEvent) => event.preventDefault();
    document.addEventListener('contextmenu', disableContextMenu);

    // 2. Bloquear impresión (Ctrl + P / Cmd + P)
    const blockPrint = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'p') {
        event.preventDefault();
        alert('La impresión está deshabilitada.');
        setTimeout(() => {
            window.stop(); // Intenta detener el proceso de impresión
          }, 0);
      }
    };
    document.addEventListener('keydown', blockPrint);

    // 3. Bloquear copiar, cortar y pegar
    const blockClipboard = (event: ClipboardEvent) => {
      event.preventDefault();
      alert('Esta acción está deshabilitada.');
    };
    document.addEventListener('copy', blockClipboard);
    document.addEventListener('cut', blockClipboard);
    document.addEventListener('paste', blockClipboard);

    // 4. Detectar intento de captura de pantalla (PrintScreen)
    const blockPrintScreen = (event: KeyboardEvent) => {
      if (event.key === 'PrintScreen') {
        alert('Captura de pantalla bloqueada.');
        clearClipboard();
      }
    };
    document.addEventListener('keyup', blockPrintScreen);

    // Limpieza de eventos al desmontar el componente
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
      document.removeEventListener('keydown', blockPrint);
      document.removeEventListener('copy', blockClipboard);
      document.removeEventListener('cut', blockClipboard);
      document.removeEventListener('paste', blockClipboard);
      document.removeEventListener('keyup', blockPrintScreen);
    };
  }, []);

  return null; // No se necesita renderizar nada en este componente
};

const clearClipboard = async () => {
    try {
      await navigator.clipboard.writeText('');
      console.log('El portapapeles ha sido limpiado.');
    } catch (error) {
      console.error('No se pudo escribir en el portapapeles:', error);
      alert('No se pudo limpiar el portapapeles. Verifica los permisos del navegador.');
    }
  };

export default SecurityLayer;
