import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Examen } from "../../interfaces/IExam";
import ExamCard from "../Cards/ExamCard";
import { getAvailableExams, inscribirExamen } from "../../services/examServices";
import { useAuth } from "../../context/AuthContext";

const InscribirExamen: React.FC = () => {
  const [exam, setExam] = useState<Examen[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { user } = useAuth();

  const loadExamData = async () => {
    setLoading(true);
    try {
      const exams = await getAvailableExams();
      setExam(exams);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const inscribieExamenUsuario = async (examenId: number) => {
    if (!user) return;

    const userId = user.id;
    setLoading(true);
    try {
      const response = await inscribirExamen(userId, examenId);
      if (response.error) {

        setAlertMessage(response.error); 
      } else {
        setAlertMessage("¡Inscripción exitosa!");

      }
    } catch (error: any) {
      console.error(error.message);
      setAlertMessage("Error al intentar inscribirse. Inténtalo de nuevo.");
    } finally {
      setLoading(false);
      setExam([]);
      loadExamData();
    }
  };

  useEffect(() => {
    loadExamData();
  }, []);

  if (loading) {
    return (
      <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
        <CardContent>
          <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
            Examenes disponibles
          </Typography>
          {exam.map((examen) => (
          <ExamCard examen={examen} callback={inscribieExamenUsuario} />
        ))}

        </CardContent>

      {/* Alert Dialog */}
      <Dialog open={!!alertMessage} onClose={() => setAlertMessage(null)}>
        <DialogTitle>Inscripción</DialogTitle>
        <DialogContent>
          <Typography>{alertMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertMessage(null)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InscribirExamen;
