import React from "react";
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Button, Stack } from "@mui/material";
import { Pregunta, Option } from "../../interfaces/IExam";

interface QuestionProps {
  question: Pregunta;
  onAnswer: (answer: string) => void;
  onNext: (skip?: boolean) => void;
  onBack?: () => void; 
  currentAnswer: string | undefined;
}

const Question: React.FC<QuestionProps> = ({ question, onAnswer, onNext, onBack, currentAnswer }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        marginBottom: 2,
      }}
    >
            <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        {onBack && (
          <Button variant="outlined" color="primary" onClick={onBack}>
            Atrás
          </Button>
        )}
        <Button variant="outlined" color="secondary" onClick={() => onNext(true)}>
          Saltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onNext()}
          disabled={!currentAnswer}
        >
          Siguiente
        </Button>
      </Stack>
      <Box sx={{ flexGrow: 1, minHeight: '40vh' }}>
        <Typography variant="h6" fontWeight={"bold"}>{question.texto}</Typography>
        <RadioGroup
          sx={{ mb: 2, mt: 2 }}
          value={currentAnswer || ""}
          onChange={(e) => onAnswer(e.target.value)}
        >
          {question.opciones.map((opcion: Option) => (
            <FormControlLabel
              key={opcion.id}
              value={opcion.texto}
              control={<Radio color="secondary" />}
              label={opcion.texto}
            />
          ))}
        </RadioGroup>
      </Box>

    </Box>
  );
};

export default Question;
