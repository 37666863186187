import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Examen } from '../../interfaces/IExam';
import { getFinishedExams } from '../../services/examServices';
import { useAuth } from '../../context/AuthContext';
import Examen1 from "../../assets/img/exam-1.png";

const DashboardWelcome: React.FC = () => {
  const navigate = useNavigate();
  const [finishedExams, setFinishedExams] = useState<Examen[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth();
  
  const handleStartExam = () => {
    navigate("/dashboard/examenes");
    console.log("Iniciando examen...");
  };

  useEffect(() => {
    const fetchFinishedExams = async () => {
      if(!user)
     return

      try {
        const userId =  user?.id;
        const exams = await getFinishedExams(userId);
        setFinishedExams(exams);
      } catch (error) {
        console.error("Error fetching finished exams:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFinishedExams();
  }, []);

  if (loading) {
    return (
      <Typography variant="h6" align="center">
        Cargando...
      </Typography>
    );
  }
  
  return finishedExams.length > 0 ? (

       <Box>
       {finishedExams.map((exam) => (
         <Card key={exam.id} sx={{ maxWidth: 250, margin: '0 auto', marginBottom: 2, borderRadius: 2 }}>
           <img 
             src={Examen1} 
             alt={exam.nombre}
             style={{ width: '100%', height: 'auto', borderRadius: '4px' }} 
           />
           <CardContent>
             <Typography  fontWeight={'bold'} component="h2" gutterBottom>
               {exam.nombre}
             </Typography>
            
             <Typography variant="body2" color="secondary">
               Pendiente de resultado
             </Typography>
             <Typography variant="body2" color="textSecondary">
               Finalizado
             </Typography>
           </CardContent>
         </Card>
       ))}
     </Box>
  ) : (
    <Card sx={{ maxWidth: 600, margin: '0 auto', padding: 2 }}>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          ¡Bienvenido a tu camino hacia la excelencia!
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Estás a un paso de comenzar tu preparación para brindar una atención médica de calidad.
          Nuestra plataforma te ofrece una amplia variedad de exámenes diseñados especialmente para 
          perfeccionar tus conocimientos y fortalecer tus habilidades clínicas. Cada prueba te acerca 
          más a la confianza y competencia necesarias para destacar en el mundo de la medicina.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Comienza tu primer examen y da el siguiente paso en tu carrera.
          Recuerda que cada respuesta es un avance hacia tu meta. ¡Estamos aquí para apoyarte en 
          cada momento de tu aprendizaje y desarrollo profesional!
        </Typography>
        <Box textAlign="center" mt={2}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleStartExam}
          >
            Comenzar Examen
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardWelcome;
